
<script setup lang="ts">
    import { ref, reactive, computed, watch, nextTick } from "vue";
    import { RModal } from "o365-responsive";
    import { type DataObject, type DataItemModel } from "o365-dataobject";

    import OButton from "./temp.OButton.vue";

    export interface IProps {
        dataObject: DataObject;
        row?: DataItemModel;
        beforeCreate?: Function;
    };

    const props = defineProps<IProps>();
    const model = defineModel<boolean>();
    
    const titleInput = ref<HTMLInputElement>();

    const freshData = { Title: "", Url: "", OpenInNewTab: false };
    const newData = reactive({ ...freshData });
    const data = computed(() => props.row || newData);

    watch([ model, titleInput ], () => {
        if (model.value) {
            titleInput.value?.focus();
            setTimeout(() => titleInput.value?.focus(), 500); // bootstrap pls
        }
    });

    const isSaving = computed<boolean>(() => props.dataObject.data.some(e => e.isSaving));
    const hasChanges = computed<boolean>(() => {
        if (props.row) {
            return props.row.hasChanges;
        } else {
            return Object.keys(freshData).some(key => data.value[key] !== freshData[key])
        }
    });
    const isDataValid = computed<boolean>(() => !!data.value.Url);
    const isAcceptAllowed = computed<boolean>(() => !isSaving.value && hasChanges.value && isDataValid.value);

    function clearData() {
        Object.keys(freshData).forEach(key => data.value[key] = freshData[key]);
    }

    async function accept() {
        if (!props.row) {
            const rowOptions = { Title: data.value.Title, Url: data.value.Url, OpenInNewTab: data.value.OpenInNewTab };
            if (props.beforeCreate) {
                Object.assign(rowOptions, props.beforeCreate());
            }
            props.dataObject.createNewAtTheEnd = true;
            props.dataObject.createNew(rowOptions);
            clearData();
        }

        await props.dataObject.save();
        await props.dataObject.load();

        model.value = false;
    }

    function cancel() {
        if (props.row) {
            props.dataObject.cancelChanges();
        } else {
            clearData();
        }

        model.value = false;
    }
</script>

<template>
    <RModal v-model="model" :title="$t('Attachment (URL)')">
        <div class="d-flex flex-column gap-3">
            <div>
                <div>{{ $t("Title") }}</div>
                <input ref="titleInput" class="form-control" v-model="data.Title" />
            </div>
            <div>
                <div>{{ $t("URL") }}</div>
                <input class="form-control" v-model="data.Url" />
            </div>
            <div class="d-flex align-items-center gap-2">
                <input id="addUrl" type="checkbox" v-model="data.OpenInNewTab" style="width: 1em; height: 1em;" />
                <label for="addUrl">{{ $t("Open in new tab") }}</label>
            </div>
        </div>
        <template #footer>
            <OButton color="primary" variant="outlined" :text="$t('Cancel')" @click="cancel()" />
            <OButton color="primary" :text="$t('OK')" :disabled="!isAcceptAllowed" :spinner="isSaving" @click="accept()" />
        </template>
    </RModal>
</template>
